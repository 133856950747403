<template>
  <div class="about">
    <div>
      <el-input style="width: 300px;margin: 0 10px 0 0"  v-model="keywords"></el-input>
      <el-button @click="search()" type="primary" style="margin:10px 0;" icon="el-icon-search"></el-button>
    </div>
    <el-table  height="calc(100vh - 255px)"  :data="tableData" style="width: 100%;">
      <el-table-column prop="name" label="名称" >
      </el-table-column>
        <el-table-column prop="platform" label="类型" >
          <template #default="scope">
          <div v-if="scope.row.type==0">通用</div>
          <div v-if="scope.row.type==1">指定城市</div>
          </template>
        </el-table-column>
        <el-table-column prop="platform" label="状态" >
          <template #default="scope">
          <el-tag size="mini" type="info"  v-if="scope.row.enabled==0">无效</el-tag>
          <el-tag size="mini" effect="dark"  v-if="scope.row.enabled==1">有效</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="platform" label="模式" >
          <template #default="scope">
          <div v-if="scope.row.model==0">固定金额</div>
          <div v-if="scope.row.model==1">距离计算</div>
          </template>
        </el-table-column>
        <el-table-column prop="platform" label="基础价钱" >
          <template #default="scope">
          ￥ {{scope.row.value}}
          </template>
        </el-table-column>
        <el-table-column prop="unit" label="单位" >
        </el-table-column>
        <el-table-column prop="base_km" label="起算公里数" >
          <template #default="scope">
            {{scope.row.base_km}} km
          </template>
        </el-table-column>
        <el-table-column prop="km_price" label="超出公里金额" >
          <template #default="scope">
            {{scope.row.km_price}} 元/km
          </template>
        </el-table-column>
<!--        <el-table-column prop="area" label="城市" >-->
<!--        </el-table-column>-->
<!--        <el-table-column prop="is_refundable" label="上门后是否可退" >-->
<!--          <template #default="scope">-->
<!--            <div v-if="scope.row.is_refundable==1" style="font-size: 25px;font-weight: 600">√</div>-->
<!--            <div v-if="scope.row.is_refundable==0" style="font-size: 25px;font-weight: 600">×</div>-->
<!--          </template>-->
<!--        </el-table-column>-->
        <el-table-column prop="address" label="操作" width="170">
          <template #header>
            <el-button  @click="add()" size="mini"  type="primary" style="margin:10px 0;">新增</el-button>
          </template>
          <template #default="scope">
            <div style="margin: 5px 0">
              <el-button-group>
                <el-button size="mini" type="primary" @click="upArea(scope.row)">城市</el-button>
                <el-button size="mini" type="primary" icon="el-icon-edit" @click="upData(scope.row)"></el-button>
                <el-button size="mini" type="primary" icon="el-icon-delete" @click="deleData(scope.row)"></el-button>
              </el-button-group>
            </div>

          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 20px">
        <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page"
            :page-sizes="[10, 20, 30, 40,50]"
            :page-size="limit"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>

      </div>
    <el-drawer
        title="新增/修改"
        v-model="drawer"
        size="40%"
        direction="rtl"
        :close-on-click-modal="false"
        destroy-on-close>
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="160px"
          class="demo-ruleForm">
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="type">
            <el-select @change="typefun" style="width: 100%;" v-model="ruleForm.type" >
              <el-option
                  v-for="item in typeArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="enabled">
            <el-select style="width: 100%;" v-model="ruleForm.enabled" >
              <el-option
                  v-for="item in enabledArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="模式" prop="model">
            <el-select @change="modelfun" style="width: 100%;" v-model="ruleForm.model" >
              <el-option
                  v-for="item in modelArr"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item :label="ruleForm.model==0?'固定金额':'起步价'" prop="value">
          <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="ruleForm.value"></el-input-number>
        </el-form-item>
        <el-form-item label="单位" prop="unit">
          <el-input v-model="ruleForm.unit"></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.model==1" key="1" label="起算公里数" prop="base_km">
          <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="ruleForm.base_km"></el-input-number>
        </el-form-item>
        <el-form-item v-if="ruleForm.model==1" key="2" label="超出公里价(元/km)" prop="km_price">
          <el-input-number style="width: 100%" :min="0" controls-position="right" v-model="ruleForm.km_price"></el-input-number>
        </el-form-item>
<!--        <el-form-item v-if="ruleForm.type==1" label="城市" prop="area">-->
<!--          <el-cascader-->
<!--              placeholder=""-->
<!--              ref="cascaderHandle"-->
<!--              style="width: 100%"-->
<!--              :options="fatherArr"-->
<!--              v-model="ruleForm.area"-->
<!--              :props="{-->
<!--                          label: 'name',-->
<!--                          value: 'id',-->
<!--                          children: 'children',-->
<!--                          checkStrictly: true-->
<!--                          }"-->
<!--              :filterable="true">-->
<!--          </el-cascader>-->
<!--        </el-form-item>-->
        <el-form-item label="上门后是否可退" prop="enabled">
          <el-select style="width: 100%;" v-model="ruleForm.is_refundable" >
            <el-option
                v-for="item in refundable"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="ruleForm.id" :loading="login" @click="okAdd('ruleForm',2)">修改</el-button>
          <el-button type="primary" v-else :loading="login" @click="okAdd('ruleForm',1)">保存</el-button>
        </el-form-item>
      </el-form>
    </el-drawer>
    <el-drawer title="城市价格"
               v-model="drawerArea"
               size="50%"
               direction="rtl"
               :close-on-click-modal="false"
               destroy-on-close>
      <Area :nowData="nowData"></Area>
    </el-drawer>
  </div>
</template>
<script>
// @ is an alias to /src
import * as axios from "@/api/subsidiaryapi"
import {getArea} from "@/api/common";
import Area from "@/components/serviceChild/area";
//本页面接口
export default {
  name: 'brand',
  components:{
    Area,
  },
  data(){
    return{
      nowData:{
        basic_fee_id:'',
      },
      login:false,
      drawer:false,
      drawerArea:false,
      tableData:[],
      page:1,
      limit:10,
      total:0,
      keywords:'',
      ruleForm:{
        name:'',
        type:0,
        enabled:1,
        model:0,
        value:0,
        unit:'',
        base_km:0,
        km_price:0,
        is_refundable:0,
      },
      typeArr:[
        {
          id:0,
          name:'通用',
        },
        {
          id:1,
          name:'指定城市',
        },
      ],
      enabledArr:[
        {
          id:0,
          name:'无效',
        },
        {
          id:1,
          name:'有效',
        },
      ],
      refundable:[
        {
          id:0,
          name:'是',
        },
        {
          id:1,
          name:'否',
        },
      ],
      modelArr:[
        {
          id:0,
          name:'固定金额',
        },
        {
          id:1,
          name:'距离计算',
        },
      ],
      fatherArr:[],
      rules:{
        name: [
          { required: true, message: '请填写名称', trigger: 'change' },
        ],
        type: [
          { required: true, message: '请选择协议类型', trigger: 'change' },
        ],
        model: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        enabled: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
        value: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
        // unit: [
        //   { required: true, message: '请填写', trigger: 'change' },
        // ],
        base_km: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
        km_price: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
        unit: [
          { required: true, message: '请填写', trigger: 'change' },
        ],
        is_refundable: [
          { required: true, message: '请选择', trigger: 'change' },
        ],
      }
    }
  },
  created() {
    this.onTable()
    this.onArea()
    this.keyupSubmit()
  },

  methods:{
    upArea(row){
      this.nowData.basic_fee_id=row.id
      this.drawerArea=true
    },
    typefun(){
    },
    modelfun(){
      this.ruleForm.km_price=0
      this.ruleForm.base_km=0
    },
    keyupSubmit(){
      document.onkeydown=e=>{
        let _key=window.event.keyCode;
        if(_key===13){
          this.search()
        }
      }
    },
    onArea(){
      getArea().then((res)=>{
        this.fatherArr=res.data
      }).catch((err)=>{

      })
    },
    onTable(){
      let form={
        page:this.page,
        limit:this.limit,
        keywords:this.keywords,
      }
        axios.getbasicData(form).then((res)=>{
          console.log(res)
          this.tableData=res.data.data
          console.log('this.tableData',this.tableData)
          this.total=res.data.total
        }).catch((err)=>{

        })
    },
    search(){
      this.page=1
      this.onTable()
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.limit=val
      this.onTable()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.page=val
      this.onTable()
    },
    add(){
      this.ruleForm={
        name:'',
        type:0,
        enabled:1,
        unit:'',
        model:0,
        value:0,
        base_km:0,
        km_price:0,
        is_refundable:0,
      }
      this.drawer=true
    },
    upData(row){
      this.ruleForm.id=row.id
      this.ruleForm.name=row.name
      this.ruleForm.enabled=row.enabled
      this.ruleForm.model=row.model
      this.ruleForm.type=row.type
      this.ruleForm.unit=row.unit
      this.ruleForm.value=Number(row.value)
      this.ruleForm.base_km=Number(row.base_km)
      this.ruleForm.km_price=Number(row.km_price)
      this.ruleForm.is_refundable=row.is_refundable
      this.drawer=true
    },
    okAdd(formName,val){
      if(this.login){
        return false
      }
      this.login=true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(val==1){
            axios.postaddbasic(this.ruleForm).then((res)=>{
                if(res.code==200){
                  this.$message.success(res.msg)
                  this.onTable()
                  let that=this
                  setTimeout(function () {
                    that.drawer=false
                    that.login=false
                  },1000)
                }else {
                  this.login=false
                  this.$message.error(res.msg)
                }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }else {
            axios.posteditbasic(this.ruleForm).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                this.login=false
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.login=false
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.login=false
              this.$message.error('服务器报错！！请联系管理员')
            })
          }


        } else {
          this.login=false
          console.log('error submit!!')
          return false
        }
      })
    },
    deleData(row){
      this.$confirm('删除!!, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
          .then(() => {
            axios.postdelbasic({id:row.id}).then((res)=>{
              if(res.code==200){
                this.$message.success(res.msg)
                this.onTable()
                let that=this
                setTimeout(function () {
                  that.drawer=false
                },1000)
              }else {
                this.$message.error(res.msg)
              }
            }).catch((err)=>{
              this.$message.error('服务器报错！！请联系管理员')
            })
          })
          .catch(() => {
            this.$message({
              type: 'error',
              message: '已取消删除',
            })
          })
    },

  }
}
</script>
<style lang="less" >
.avatar-uploader .el-upload {
  border: 1px dashed #c1c1c1;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.el-upload{

}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.el-table .el-table__cell{
    padding: 0;
}
</style>